import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'reviewInput']

  connect() {
    this.openContainer()
    this.scrollToForm()
  }

  remove() {
    const form = this.formTarget
    if (form) form.remove()
  }

  scrollToForm() {
    const review = this.reviewInputTarget

    if (review) {
      review.scrollIntoView({ block: 'center' })
      review.focus()
    }
  }

  openContainer() {
    const el = this.element.closest('li')
    const containerController = this.application.getControllerForElementAndIdentifier(el, 'groups-nav--component')
    containerController.toggleState(true)
  }
}
