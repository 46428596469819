import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form']

  change(event) {
    this.element.closest("form").requestSubmit(this.buttonTarget)
    // event.preventDefault()
    //
    // // const checked = event.currentTarget.checked
    //
    // this.formTargets.forEach(el => {
    //   const radio = el.querySelector('input[type=radio]')
    //   const form = el.querySelector('fieldset')
    //
    //   form.classList.toggle('is-hidden', radio !== event.currentTarget)
    //
    //   if(radio === event.currentTarget) {
    //     form.scrollIntoView({block: "center", inline: "nearest"})
    //   }
    // })
  }
}
