import { Controller } from 'stimulus'

export default class extends Controller {
  // static targets = ['main', 'thumb']

  // initialize() { this.imageIndex = 0 }

  // connect() { this.update() }

  // show(event) {
  //   event.preventDefault()
  //   const thumbnail = event.currentTarget.closest('.product-gallery__thumbnail')
  //   this.imageIndex = this.thumbTargets.indexOf(thumbnail)
  //   this.update()
  // }

  next() {
    // this.imageIndex = (this.imageIndex == this.thumbTargets.length - 1) ? 0 : this.imageIndex + 1
    // this.update()
    //
    const e = new CustomEvent("product-gallery:next")
    window.dispatchEvent(e)
  }

  prev() {
    // console.log('prev')
    // this.imageIndex = (this.imageIndex == 0) ? (this.thumbTargets.length - 1) : this.imageIndex - 1
    // this.update()
    const e = new CustomEvent("product-gallery:prev")
    window.dispatchEvent(e)
  }

  // update() {
  //   const selectedEl = this.thumbTargets[this.imageIndex]
  //
  //   if (selectedEl) {
  //     // highlight thumbnail
  //     this.thumbTargets.forEach(el => {
  //       el.classList.toggle('product-gallery__thumbnail--selected', el === selectedEl)
  //     })
  //
  //     // update main image
  //     this.mainTarget.src = selectedEl.querySelector('a').href
  //   }
  // }
}
