// https://ckeditor.com/docs/ckeditor5/latest/framework/guides/deep-dive/upload-adapter.html

import { DirectUpload } from '@rails/activestorage'

class UploadAdapter {
  constructor(loader) {
    console.log('Init custom uploader ...')
    this.loader = loader;
  }

  async upload() {
    console.log('Upload with Init custom uploader ...')

    // Return a promise that will be resolved when the file is uploaded.
    const file = await this.loader.file;
    const adapter = this

    return new Promise((resolve, reject) => {
      adapter.uploader = new DirectUpload(file, "/uploads/direct_uploads", adapter)
      adapter.uploader.create((error, blob) => {
        if (error) {
          reject()
        } else {
          const url = `/uploads/blobs/proxy/${blob.signed_id}/${blob.filename}`
          resolve({ default: url });
        }
      })
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.setRequestHeader('x-amz-acl', 'public-read')

    this.request = request
    this.request.upload.addEventListener("progress", this.directUploadDidProgress.bind(this))
  }

  directUploadDidProgress(event) {
    if (event.lengthComputable) {
      this.loader.uploadTotal = event.total;
      this.loader.uploaded = event.loaded;
    }
  }

  // Aborts the upload process.
  abort() {
    if (this.request) {
      this.request.abort()
    }
  }
}

function UploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    // Configure the URL to the upload script in your back-end here!
    return new UploadAdapter(loader)
  }
}

export default UploadAdapterPlugin
