import { Controller } from 'stimulus'

// https://gist.github.com/AlexGh12/2a098a8f5e2fac5482b01776383125c4?permalink_comment_id=4758065#gistcomment-4758065
export default class extends Controller {
  initialize() {
    this.isDown = false;
    this.preventClick = false;
    this.startX;
    this.scrollLeft;
  }

  connect() {
    this.element.addEventListener('mousedown', this.handleMouseDown.bind(this));
    this.element.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
    this.element.addEventListener('mouseup', this.handleMouseUp.bind(this));
    this.element.addEventListener('mousemove', this.handleMouseMove.bind(this));
    this.element.addEventListener('click', this.handleClick.bind(this));
  }

  disconnect() {
    this.element.removeEventListener('mousedown', this.handleMouseDown.bind(this));
    this.element.removeEventListener('mouseleave', this.handleMouseLeave.bind(this));
    this.element.removeEventListener('mouseup', this.handleMouseUp.bind(this));
    this.element.removeEventListener('mousemove', this.handleMouseMove.bind(this));
    this.element.removeEventListener('click', this.handleClick.bind(this));
  }

  handleMouseDown(e) {
    this.isDown = true;
    this.element.classList.add('drag-to-scroll');
    this.startX = e.pageX - this.element.offsetLeft;
    this.scrollLeft = this.element.scrollLeft;
    this.preventClick = false;
  }

  handleMouseLeave() {
    this.isDown = false;
    this.element.classList.remove('drag-to-scroll');
  }

  handleMouseUp() {
    this.isDown = false;
    this.element.classList.remove('drag-to-scroll');
  }

  handleMouseMove(e) {
    if (!this.isDown) return;
    e.preventDefault();
    const x = e.pageX - this.element.offsetLeft;
    const walk = (x - this.startX) * 3; //scroll-fast
    this.element.scrollLeft = this.scrollLeft - walk;
    this.preventClick = true;
  }

  handleClick(e) {
    if (this.preventClick) {
      e.preventDefault();
    }
  }
}
