import { Controller } from 'stimulus'
import { useClickOutside } from 'stimulus-use'
import { useMediaBreakPoints } from "controllers/mixins/media_break_points"

export default class extends Controller {
  static targets = ['videos']

  initialize() {
    // console.log('Product card controller')
    this.visible = false
  }

  connect() {
    useMediaBreakPoints(this)
    useClickOutside(this)
  }

  clickOutside() {
    console.log('click outside')
    // e.preventDefault()
    this.videosTarget.classList.remove('product-card__videos--visible')
    this.scrollOff()
  }

  toggleVideo(e) {
    e.preventDefault()
    e.stopPropagation()

    // hide all the opened playlists
    document.querySelectorAll('.product-playlist').forEach(el => el.classList.remove('product-card__videos--visible'))

    this.visible = !this.visible
    if (this.visible) {
      this.videosTarget.classList.add('product-card__videos--visible')
    }

    if (this.isMobile) {
      this.scrollToggle()
    }
  }

  hideVideos(e) {
    e.preventDefault()
    e.stopPropagation()

    this.videosTarget.classList.remove('product-card__videos--visible')
    this.scrollOff()
  }

  scrollToggle() {
    this.visible ? this.scrollOn() : this.scrollOff()
  }

  scrollOn() {
    document.documentElement.style.overflow = 'hidden'  // firefox, chrome
    document.body.scroll = 'no'
  }

  scrollOff() {
    document.documentElement.style.overflow = 'auto'  // firefox, chrome
    document.body.scroll = 'yes' // ie only
  }
}
