import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  preview(e) {
    // const input = this.inputTarget
    // const image = this.element.querySelector('img')
    //
    // if (input.files && input.files[0]) {
    //   var reader = new FileReader();
    //
    //   reader.onload = function() {
    //     image.src = reader.result
    //   }
    //
    //   reader.readAsDataURL(input.files[0]);
    // }

    this.element.closest("form").requestSubmit();
  }
}
