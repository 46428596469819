import { Controller } from 'stimulus'
import { useOverlay } from "controllers/mixins/overlay"
import 'sharer.js'

export default class extends Controller {
  static targets = ['modal', 'medium', 'placeholder', 'copy']

  connect() {
    useOverlay(this)
  }

  copy(event) {
    if (window.navigator.clipboard) {
      window.navigator.clipboard.writeText(event.currentTarget.dataset.url);
      this.close()
    }
  }

  close() {
    this.hideOverlay()
  }

  open(event) {
    const url = event.currentTarget.dataset.url
    const description = event.currentTarget.dataset.description
    const hashtags = event.currentTarget.dataset.hashtags

    this.placeholderTarget.textContent = url
    this.copyTarget.dataset.url = url

    for (const item of this.mediumTargets) {
      item.dataset.url = url
      item.dataset.title = description
      // Facebook accepts just one hashtag
      if (item.dataset.sharer === 'facebook') {
        item.dataset.hashtag = hashtags?.split(',')[0]
      } else {
        item.dataset.hashtags = hashtags
      }
    }

    this.showOverlay()
    this.showModal()
  }

  showModal() {
    this.modalTarget.classList.remove('is-hidden')
  }

  async share({ detail: event }) {
    console.log('share ....')
    console.log(event)

    if (this.hasNativeSocialMediaAppsSupported) {
      try {
        await navigator.share({
          title: event.currentTarget.dataset.title,
          text: event.currentTarget.dataset.description,
          url: event.currentTarget.dataset.url,
        });
      } catch {
        this.open(event);
      }
    } else {
      this.open(event);
    }
  }

  get hasNativeSocialMediaAppsSupported() {
    console.log(navigator.share)
    console.log(navigator.userAgent)

    return navigator.share && (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
    )
  }
}
