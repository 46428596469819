import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { class: String }
  static targets = ['detailsItem']

  toggle() {
    this.detailsItemTarget.classList.toggle(`${this.classValue}--hidden-details`)
  }
}
