import { Controller } from 'stimulus'
import Uploader from 'lib/uploader'

export default class extends Controller {
  static targets = [
    'inputSignedId',
    'previewImage',
    'progressTemplate',
    'progressWrapper',
    'closeButton'
  ]

  remove() {
    this.element.remove()
  }

  abortUpload(e) {
    e.preventDefault()
    if (this.uploadInstance) {
      this.uploadInstance.abort()
    }
    this.remove()
  }

  async upload(file, url) {
    this.showPreview(file)
    this.showProgress(file)

    this.uploadInstance = new Uploader(file, url, this.progressWrapperTarget)

    try {
      const blob = await this.uploadInstance.run()
      this.success(blob)
    } catch (e) {
      this.showError(e)
    }
  }

  showPreview(file) {
    if (this.hasPreviewImageTarget) {
      this.previewImageTarget.src = window.URL.createObjectURL(file)
    } else {
      const image = document.createElement('img')
      image.src = window.URL.createObjectURL(file)
      this.element.appendChild(image)
    }
  }

  // eslint-disable-next-line no-unused-vars
  showProgress(file) {
    this.element.innerHTML += this.progressTemplateTarget.innerHTML
  }

  showError(error) {
    console.error(error)
    this.element.removeChild(this.progressWrapperTarget)
  }

  success(blob) {
    this.inputSignedIdTarget.value = blob.signed_id
    this.element.removeChild(this.progressWrapperTarget)
    this.displayCloseButton()
  }

  displayCloseButton() {
    this.closeButtonTarget.classList.remove('is-hidden')
  }
}
