import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    path: String
  }

  connect() {
    document.documentElement.addEventListener('turbo:frame-load', this.loaded.bind(this))
    window.addEventListener('popstate', this.popstate.bind(this))
  }

  disconnect() {
    document.documentElement.removeEventListener('turbo:frame-load', this.loaded.bind(this))
    window.removeEventListener('popstate', this.popstate.bind(this))
  }

  loaded(event) {
    if(this.popStateAction) {
      this.popStateAction = false
      return
    }

    const src = event.srcElement.src
    if (event.srcElement.isSameNode(this.element)) {
      history.pushState(this.historyState(), "", src)
    }
  }

  popstate() {
    if(this.pathValue !== window.location.pathname) {
      this.popStateAction = true
      this.element.src = window.location.href
    } else {
      this.element.removeAttribute('src')
      this.element.innerHTML = ''
    }
  }

  close() {
    history.pushState({}, "", this.pathValue)
    this.element.removeAttribute('src')
    this.element.innerHTML = ''
  }

  historyState() {
    return {
      turbo_frame_history: true,
      turbo_frame: this.element.id,
    }
  }
}
