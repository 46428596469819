import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.inputTarget.classList.add(`amount-input__input--${this.value.toString().length}`)
  }

  add(e) {
    e.preventDefault()
    this.increment()
  }

  sub(e) {
    e.preventDefault()
    this.decrement()
  }

  changeValue(e) {
    e.preventDefault()
    // let newValue = this.inputTarget.value // + e.charCode
    let newValue = e.currentTarget.value

    if (newValue === '') {
      // console.error('empty value')
      return
    }

    // console.log(`new value: ${newValue}`)

    this.updateLength(this.value, newValue)
    this.inputTarget.setAttribute('value', newValue)
    this.update()
  }

  decrement() {
    this.increment(-1)
  }

  increment(step = 1) {
    let newValue = this.value + step
    newValue = Math.max(newValue, 1)

    this.updateLength(this.value, newValue)
    this.inputTarget.setAttribute('value', newValue)

    this.update()
  }

  update() {
    const form = this.element.closest('form.amount-updater')
    form.requestSubmit()
  }

  updateLength(oldValue, newValue) {
    this.inputTarget.classList.remove(...this.inputTarget.classList);
    this.inputTarget.classList.add(`amount-input__input`)
    this.inputTarget.classList.add(`amount-input__input--${newValue.toString().length}`)
  }

  get value() {
    return parseInt(this.inputTarget.value, 10)
  }
}
