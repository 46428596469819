import { ApplicationController, useClickOutside } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['removeLink', 'textarea', 'editable']
  static values = {
    reply: Boolean
  }

  connect() {
    if(this.replyValue) {
      useClickOutside(this)
    }
  }

  updateTextarea() {
    this.textareaTarget.value = this.editableTarget.textContent
  }

  remove() {
    if(!this.textareaTarget.value) {
      this.removeLinkTarget.click()
    }
  }

  clickOutside() {
    this.remove()
  }
}
