import { Controller } from 'stimulus'

export default class extends Controller {
  close() {
    // TODO: may be sent an event modal:closed with some modal name
    this.element.remove();
  }

  keydown(e) {
    if (e.key === 'Escape') this.close()
  }
}
