import { Controller } from "stimulus";

export default class extends Controller {
  static values = { with: String };

  connect() {
    // this.element.dataset['action'] = 'submit->checkout--form#disableForm'

    if (!this.hasWithValue) {
      this.withValue = "Processing...";
    }
  }

  disableForm(event) {
    // console.log('disable form ...')
    // console.log(event.detail.formSubmission)

    if (!event) return;
    if (!event.detail.formSubmission.submitter) return; // called indirect (not from button press)

    this.submitButton.disabled = true;
    this.preservedButtonLabel = this.submitButton.innerHTML;
    this.submitButton.innerHTML = this.withValue;

    this.formFields.forEach((element) => {
      element.classList.add("form__field--disabled");
    });
    this.formErrors.forEach((element) => {
      element.remove();
    });
  }

  enableForm(event) {
    // console.log('enable form ...')
    // console.log(event.detail.formSubmission)
    // console.log(event.detail.formSubmission.response.url)

    if (!event) return;
    if (!event.detail.formSubmission.submitter) return; // called indirect (not from button press)

    this.submitButton.disabled = false;
    this.submitButton.innerHTML = this.preservedButtonLabel;

    this.formFields.forEach((element) => {
      element.classList.remove("form__field--disabled");
    });
  }

  get formErrors() {
    return this.element.querySelectorAll(".form__error");
  }

  get submitButton() {
    return this.element.querySelector(".button--primary");
  }

  get formFields() {
    return this.element.querySelectorAll(".form__field");
  }
}