import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {

  get mainMenuController() {
    return this.application.controllers.find(c => c.identifier === 'main-menu')
  }

  get mainSubmenuController() {
    return this.application.controllers.find(c => c.identifier === 'main-submenu')
  }

  get mainOverlayController() {
    return this.application.controllers.find(c => c.identifier === 'main-overlay')
  }

  toggleMenu(event) {
    const menuId = event.detail.menu.id

    this.mainMenuController.activate(menuId)
    const visible = this.mainSubmenuController.activate(menuId)

    this.mainOverlayController.toggle(!visible)
  }

  hideMenu() {
    if (this.mainMenuController) this.mainMenuController.hide()
    if (this.mainSubmenuController) this.mainSubmenuController.hide()

    this.mainOverlayController.toggle(false)
  }
}
