// import { Controller } from 'stimulus'
import { ApplicationController } from 'stimulus-use'
// import { useClickOutside } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['item']
  static values = { itemId: String }

  initialize() {
    this.currentMenuId = null
  }

  // connect() {
  //   useClickOutside(this, {
  //     onlyVisible: true
  //   })
  // }
  //
  // start() {
  //   console.log('enable')
  //   this.enabled = false
  // }

  // clickOutside(event) {
  //   event.preventDefault()
  //
  //   console.log('click outside')
  //
  //   // const submenu = document.querySelector('.navbar--submenu')
  //   // submenu.classList.toggle('is-active', false)
  //
  //   // const submenus = Array.from(document.querySelectorAll(`.main-submenu-wrapper`))
  //   // submenus.forEach(e => e.classList.toggle('main-submenu-wrapper--active', false))
  // }

  showWrapper() {
    const submenu = document.querySelector('.navigation-bar--submenu')
    submenu.classList.toggle('is-active', true)
  }

  hideWrapper() {
    const submenu = document.querySelector('.navigation-bar--submenu')
    submenu.classList.toggle('is-active', false)
  }

  activate(menuId) {
    if(menuId === this.currentMenuId) {
      this.hideWrapper()
      this.currentMenuId = null
      // this.itemControllers.forEach(item => item.activate(menuId))

      return false
    } else {
      this.showWrapper()
      this.currentMenuId = menuId
      // this.itemControllers.forEach(item => item.activate(menuId))

      this.itemTargets.forEach(e => {
        e.classList.toggle('main-submenu-wrapper--active', e.dataset.mainSubmenuItemIdValue === menuId)
      })

      return true
    }
  }

  // get itemControllers() {
  //   return this.application.controllers.filter(c => c.identifier === 'main-submenu-item')
  // }

  hide() {
    this.hideWrapper()
    this.currentMenuId = null
  }
}
