import { Controller } from 'stimulus'
import { loadStripe } from '@stripe/stripe-js'

export default class extends Controller {
  static values = {
    config: Object
  }

  static targets = [
    'cardError',
    'cardErrorTemplate',
    'number',
    'expiry',
    'cvc',
    'token',
    'lastDigits',
    'month',
    'year',
    'ccType'
  ]

  connect() {
    console.log('stripe init')

    loadStripe(this.configValue.publishable_key).then(stripe => {
      console.log('stripe init 2')
      this.stripe = stripe
      this.mountElements()
    })

    this.form.addEventListener('submit', this.createStripeToken.bind(this))
    // this.form.addEventListener('turbo:submit-start', event => this.createStripeToken(event))
  }

  disconnect() {
    this.form.removeEventListener('submit', this.createStripeToken.bind(this))
    // this.form.removeEventListener('turbo:submit-start', event => this.createStripeToken(event))
  }

  mountElements() {
    const elements = this.stripe.elements()

    // this.cardNumber = elements.create('cardNumber', {})
    // this.cardNumber.mount(this.numberTarget)
    //
    // var cardExpiry = elements.create('cardExpiry', {})
    // cardExpiry.mount(this.expiryTarget)
    //
    // var cardCvc = elements.create('cardCvc', {})
    // cardCvc.mount(this.cvcTarget)

    // Create and mount the Payment Element
    const paymentElement = elements.create('payment');
    paymentElement.mount('#payment-element');
  }

  async createStripeToken(event) {
    event.preventDefault()
    console.log('on submit')

    this.clearErrorMessage()

    console.log('on submit2')
    const result = await this.stripe.createToken(this.cardNumber)

    console.log('on submit3')

    console.log(result)

    if (result.error) {
      console.log('onerrorsubmit')
      this.displayError(result.error.message)
      return false
    } else {
      console.log('submit')
      return this.submitStripeResults(result.token);
    }
  }

  clearErrorMessage() {
    if (this.hasCardErrorTarget) {
      this.cardErrorTarget.remove()
    }
  }

  displayError(message) {
    this.clearErrorMessage()

    this.element.insertAdjacentHTML("afterbegin", this.cardErrorTemplateTarget.innerHTML)
    this.cardErrorTarget.querySelector('.notification-content__state-text').insertAdjacentHTML("beforeend", message)
  }

  submitStripeResults(token) {
    console.log('assign ...')

    this.tokenTarget.value = token.id
    this.lastDigitsTarget.value = token.card.last4
    this.monthTarget.value = token.card.exp_month
    this.yearTarget.value = token.card.exp_year
    this.ccTypeTarget.value = this.ccTypeMap(token.card.brand || token.card.type)

    return this.form.submit()
  }

  ccTypeMap(source) {
    const map = {
      mastercard:  'mastercard',
      visa: 'visa',
      amex: 'amex',
      discover: 'discover',
      diners: 'dinersclub',
      jcb: 'jcb',
      unionpay: 'unionpay',
      'american express': 'amex',
      'diners club': 'dinersclub',
    }

    return map[source.toLowerCase()]
  }

  get form() {
    return this.element.closest('form')
  }
}
