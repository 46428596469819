import { Controller } from 'stimulus'

export default class extends Controller {
  // static values = {
  //   name: String
  // }

  open(event) {
    this.dispatch("modal:show", { detail: event })
  }

  close(event) {
    this.dispatch("modal:hide", { detail: event })
  }
}
