import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["emailForm"]

  toggleEmailForm(event) {
    event.preventDefault()
    this.emailFormTarget.classList.toggle('order-to-email__form--visible')
  }
}
