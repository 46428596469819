export default {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Montserrat:400,500",
    },
  ],

  appearance: {
    theme: "stripe", // https://stripe.com/docs/elements/appearance-api#theme
    variables: {
      fontFamily: "Montserrat",
      spacingGridRow: "24px",
      colorIconTab: "#9560A9",
      colorIconTabHover: "#9560A9",
      colorIconTabSelected: "#9560A9",
    },
    rules: {
      ".Tab": {
        backgroundColor: "#FFF",
        borderRadius: "2px",
        border: "1px solid #9560A9",
      },
      ".TabLabel": {
        color: "#9560A9",
      },
      ".Input": {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "20px",
        color: "#333333",
        border: "1px solid #A1A1A1",
        borderRadius: "2px",
        boxShadow: "none",
      },
      ".Input:focus": {
        border: "1px solid #000000",
        boxShadow: "none",
      },
      ".Input::placeholder": {
        color: "rgba(51, 51, 51, 0.4)",
      },
      ".Label": {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "17px",
        color: "#333333",
      },
    },
  },
};