import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'toggleButton' ]

  change(e) {
    e.preventDefault()
    this.element.closest("form").requestSubmit(this.toggleButtonTarget)
  }
}
