export const useMediaBreakPoints = (controller) => (
  Object.assign(controller, {
    get isMobile() {
      return window.matchMedia("(max-width: 769px)").matches
    },

    get isTablet() {
      return window.matchMedia("(max-width: 1024px)").matches
    }
  })
)
