import { useClickOutside } from 'stimulus-use'
import Cropper from 'cropperjs'
import Controller from '../image_uploader/component_controller'
import { useOverlay } from "controllers/mixins/overlay"

export default class extends Controller {
  static targets = [
    'cropperModal',
    'cropperImage'
  ]

  connect() {
    useClickOutside(this)
    useOverlay(this)
  }

  clickOutside() {
    this.cancel()
  }

  disconnect() {
    super.disconnect()
    if (this.cropper) {
      this.cropper.destroy()
    }
  }

  success(blob, file) {
    super.success(blob, file)
    this.inputSignedIdTarget.form.requestSubmit()
  }

  upload(e) {
    super.highlight(false)
    const files = e.dataTransfer ? e.dataTransfer.files : e.currentTarget.files
    this.showCropper(files[0])
  }

  showCropper(file) {
    this.file = file
    const blobURL = URL.createObjectURL(file)
    this.cropperImageTarget.src = blobURL
    this.displayCropper(true)

    this.cropper = new Cropper(this.cropperImageTarget, {
      aspectRatio: 1,
      viewMode: 1
    })
  }

  crop(e) {
    e.preventDefault()
    this.cropper.getCroppedCanvas().toBlob((blob) => {
      const file = new File([blob], this.file.name, { type: this.file.type });
      this.displayCropper(false)
      super.uploadFile(file)
    })
  }

  cancel(e) {
    if (e) e.preventDefault()
    if (this.cropper) {
      this.displayCropper(false)
      this.fileFieldTarget.value = null
      this.cropper.destroy()
      delete this.cropper
    }
  }

  displayCropper(visible) {
    this.cropperModalTarget.classList.toggle('is-hidden', !visible)
    if(visible) {
      this.showOverlay()
    } else {
      this.hideOverlay()
    }
  }
}
