import { Controller } from 'stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['selected', 'option', 'options', 'value', 'hiddenInput']

  connect() {
    useClickOutside(this)
  }

  clickOutside() {
    this.close()
  }

  close() {
    this.element.classList.remove('custom-select--open')
  }

  toggle(e) {
    e.preventDefault()
    this.element.classList.toggle('custom-select--open')
  }

  disable() {
    this.selectedTarget.setAttribute('disabled', '')
  }

  enable() {
    this.selectedTarget.removeAttribute('disabled')
  }

  select() { }

  selectItem(e) {
    this.valueTarget.innerHTML = e.target.textContent
    this.hiddenInputTarget.value = e.target.dataset.value
    this.close()
    this.select(e)
  }
}