import Dropdown from 'controllers/shared/dropdown_controller'

export default class extends Dropdown {
  static targets = ['button']

  select(e) {
    e.preventDefault()
    this.element.closest("form").requestSubmit(this.buttonTarget)
  }
}
