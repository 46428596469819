import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = [
    'newCreditCard',
    'existingCards'
  ]

  change(event) {
    event.preventDefault()

    this.newCreditCardTargets.forEach(e => e.classList.toggle('is-hidden', event.currentTarget.value == 'yes'))
    this.existingCardsTarget.classList.toggle('is-hidden', event.currentTarget.value != 'yes')
  }
}
