import { Controller } from 'stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = [
    'input',
    'dropdown',
    'item'
  ]

  connect() {
    useClickOutside(this)
  }

  clickOutside() {
    if (this.resultsPresent) {
      this.close()
    }
  }

  get resultsPresent() {
    return this.hasDropdownTarget && this.dropdownTarget.innerHTML.length > 0
  }

  get dropdownItem() {
    return this.currentItem || this.itemTargets[0] // selected item or a first one
  }

  lookup() {
    if (this.inputTarget.value.length == 0) { return this.close() }

    this.currentItem = null
    this.element.closest('form').requestSubmit()
  }

  // Emulate click on native close button
  close() {
    this.inputTarget.value = ''

    if (this.hasDropdownTarget) {
      this.dropdownTarget.innerHTML = ''
    }
  }

  keydown(e) {
    if (this.resultsPresent && ['ArrowUp', 'ArrowDown'].includes(e.code)) {
      e.preventDefault()
      e.code == 'ArrowUp' ? this.selectPrevious() : this.selectNext()
      this.highlightActive()
    }

    if (e.code == 'Enter') {
      e.preventDefault();

      if (this.resultsPresent) {
        this.dropdownItem.querySelector('button, a').click()
      } else {
        Turbo.visit('/search?q=' + this.inputTarget.value) // FIXME: this is a aprt of main search ... need to fix this shit
      }
    }

    if (e.code == 'Escape') {
      this.close();
    }
  }

  selectNext() {
    const nextItem = this.currentItem && this.currentItem.nextElementSibling
    this.currentItem = nextItem == null ? this.itemTargets[0] : nextItem
  }

  selectPrevious() {
    const nextItem = this.currentItem && this.currentItem.previousElementSibling
    this.currentItem = nextItem == null ? [...this.itemTargets].pop() : nextItem
  }

  highlightActive() {
    if (!this.resultsPresent) return

    this.currentItem.scrollIntoView(false)
    this.itemTargets.forEach(item => item.classList.remove('autosuggest__item--active'))
    this.currentItem.classList.add('autosuggest__item--active')
  }
}
