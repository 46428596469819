import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['header', 'caret']

  async toggle() {
    if (this.caretVisible) {
      this.headerTarget.classList.toggle('groups-nav__header--open')
    }
  }

  get caretVisible() {
    const el = this.caretTarget
    const style = window.getComputedStyle(el)
    return (style.display !== 'none')
  }
}

