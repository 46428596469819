import { Controller } from 'stimulus'
import { get } from '@rails/request.js'
// import ClassicEditor from 'lib/ckeditor'
import UploadAdapterPlugin from 'lib/ckeditor/upload_adapter'
import MentionCustomization from 'lib/ckeditor/mention_customization'

export default class extends Controller {
  static values = {
    shopsMentionUrl: String,
    type: String
  }

  async connect() {
    const config = this.config()
    try {
      const ClassicEditor = (await import("lib/ckeditor")).default
      this.editor = await ClassicEditor.create(this.element, config)
    } catch (e) {
      console.error(e)
    }
  }

  disconnect() {
    if (this.editor) {
      this.editor.destroy()
    }
  }

  async getFeedItems(queryText) {
    if (!queryText) return []

    const url = `${this.shopsMentionUrlValue}?q=${queryText}`
    const { response } = await get(url)
    if (response.ok) {
      const results = await response.json()
      return results.map(v => ({ id: `@${v.name}`, name: v.name, link: v.url }))
    }
  }

  config() {
    switch (this.typeValue) {
      case 'simple':
        return {
          toolbar: {
            items: ['bold', 'italic', 'link']
          }
        }
      case 'minimal':
        return {
          toolbar: {
            items: []
          }
        }
      case 'advanced':
        return {
          extraPlugins: ['Mention', MentionCustomization],
          mention: {
            feeds: [
              {
                marker: '@',
                feed: this.getFeedItems.bind(this),
              }
            ]
          },
          toolbar: {
            items: [
              'heading',
              '|',
              'bold',
              'italic',
              '|',
              'bulletedList',
              'numberedList',
              '|',
              'undo',
              'redo'
            ]
          }
        }
      default:
        return {
          extraPlugins: [UploadAdapterPlugin],
          mediaEmbed: {
            previewsInData: true
          },
          image: {
            resizeOptions: [
              {
                  name: 'resizeImage:original',
                  value: null,
                  label: 'Original'
              },
              {
                  name: 'resizeImage:custom',
                  label: 'Custom',
                  value: 'custom'
              },
              {
                  name: 'resizeImage:40',
                  value: '40',
                  label: '40%'
              },
              {
                  name: 'resizeImage:60',
                  value: '60',
                  label: '60%'
              }
            ],
            toolbar: [
              'resizeImage',
              '|',
              'imageTextAlternative',
              'toggleImageCaption',
              '|',
              'imageStyle:inline',
              'imageStyle:block',
              'imageStyle:side',
              '|',
              'imageStyle:alignLeft',
              'imageStyle:alignRight',
              '|',
              'linkImage'
            ],
            styles: [
              'alignLeft',
              'alignCenter',
              'alignRight'
            ]
          }
        }
    }
  }
}
