import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['popover']
  static values = {
    text: String
  }

  copy() {
    if (window.navigator.clipboard) {
      window.navigator.clipboard.writeText(this.textValue)
      this.openPopoverMessage()
    }
  }

  openPopoverMessage() {
    this.togglePopoverVisibility(true)
    setTimeout(() => {
      this.togglePopoverVisibility(false)
    }, 5000)
  }

  togglePopoverVisibility(isVisible) {
    this.popoverTarget.classList.toggle('is-hidden', !isVisible)
  }
}
