import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['main', 'thumb']

  initialize() { this.imageIndex = 0 }

  connect() { this.update() }

  show(event) {
    event.preventDefault()
    const thumbnail = event.currentTarget.closest('.product-gallery__thumbnail')
    this.imageIndex = this.thumbTargets.indexOf(thumbnail)
    this.update()
  }

  get currentImage() {
    const el = this.thumbTargets[this.imageIndex]
    return el.querySelector('a').href
  }

  next() {
    this.imageIndex = (this.imageIndex == this.thumbTargets.length - 1) ? 0 : this.imageIndex + 1
    this.update()
  }

  prev() {
    this.imageIndex = (this.imageIndex == 0) ? (this.thumbTargets.length - 1) : this.imageIndex - 1
    this.update()
  }

  update() {
    const e = new CustomEvent("product-photo-changed", { detail: { 
      image: this.currentImage 
    }})
    window.dispatchEvent(e)

    this.highlightThumbnail()
  }

  highlightThumbnail() {
    const selected = this.thumbTargets[this.imageIndex]

    if (selected) {
      this.thumbTargets.forEach(el => {
        el.classList.toggle('product-gallery__thumbnail--selected', el === selected)
      })
    }
  }
}
