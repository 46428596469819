import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const tag = params["tag"]

    this.element.classList.toggle("video-post__tag--active", tag === this.element.dataset.tag)
  }
}
