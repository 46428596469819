export const useOverlay = (controller) => (
  Object.assign(controller, {
    showOverlay() {
      this.dispatch('main-overlay:show', {
        prefix: false // we dont need to prefix event with sender controller
      })
    },

    hideOverlay() {
      this.dispatch('main-overlay:hide', {
        prefix: false // we dont need to prefix event with sender controller
      })
    }
  })
)
