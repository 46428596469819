import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['main']

  update(event) {
    event.preventDefault()
    this.mainTarget.src = event.detail.image
  }
}
