import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    window.addEventListener('scroll', this.show.bind(this))
  }

  disconnect() {
    window.removeEventListener('scroll', this.show.bind(this))
  }

  show() {
    const threshold = window.screen.height / 3
    const visible = document.body.scrollTop > threshold || document.documentElement.scrollTop > threshold
    this.element.classList.toggle('is-hidden', !visible)
  }
}
