// import 'core-js/stable'
// import 'regenerator-runtim /runtime'
// import "@fortawesome/fontawesome-free/js/all"
// import 'channels'

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo
Turbo.session.drive = false

// import Rails from '@rails/ujs'
// Rails.start()
// window.Rails = Rails

import * as ActiveStorage from '@rails/activestorage'
ActiveStorage.start()

import 'controllers/storefront'
// import 'controllers/shop';

require.context('../../images', true, /\.(gif|jpg|png|svg)$/i)