import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['item', 'header', 'body']

  get controllersList() {
    return this.application.controllers.filter(controller => {
      return (
        controller.context.identifier === this.identifier &&
        controller.element.parentNode.isSameNode(this.element.parentNode) &&
        !controller.element.isSameNode(this.element)
      )
    })
  }

  get parentController() {
    return [...this.application.controllers].reverse().find(controller => {
      return (
        controller.context.identifier === this.identifier &&
        !controller.element.isSameNode(this.element) &&
        controller.element.contains(this.element)
      )
    })
  }

  get childControllers() {
    return [...this.application.controllers].filter(controller => {
      return (
        controller.context.identifier === this.identifier &&
        !controller.element.isSameNode(this.element) &&
        this.element.contains(controller.element)
      )
    })
  }

  toggle() {
    this.itemTarget.classList.toggle('taxon-menu-list-item--focused')

    if(this.parentController || this.itemTarget.dataset.isDesktop) {
      for(const controller of this.controllersList) {
        controller.itemTarget.classList.toggle('taxon-menu-list-item--active')
      }
    }

    for(const controller of this.childControllers) {
      controller.itemTarget.classList.toggle('taxon-menu-list-item--active', true)
    }

    this.parentController?.headerTarget.classList.toggle('is-hidden')
    this.parentController?.bodyTarget.classList.toggle('taxon-menu-list-item__body--no-margin')
  }
}
