import { IntersectionController  } from 'stimulus-use'

export default class extends IntersectionController {
  static targets = [ 'video' ]
  static values = {
    controls: Boolean
  }

  async connect() {
    const [videoTarget] = this.videoTargets.slice(-1)
    if(videoTarget) {
      const videojs = (await import("video.js")).default
      this.player = videojs(videoTarget, {
        controls: this.controlsValue,
        autoplay: false,
        muted: false,
        loop: false,
        preload: 'none'
      })
    }
  }

  disconnect() {
    if(this.player) {
      this.player.dispose()
    }
  }
}
