import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = [
    'fileField',
    'previewTemplate',
    'imageInput'
  ]

  upload(e) {
    this.highlight(false)

    const files = e.dataTransfer ? e.dataTransfer.files : e.currentTarget.files
    Array.from(files).forEach(file => this.uploadFile(file))

    e.currentTarget.value = ''
  }

  uploadFile(file) {
    const itemWrapper = this.showItemWrapper()

    Promise.resolve().then(() => {
      const url = this.fileFieldTarget.dataset.directUploadUrl
      const imageController = this.application.getControllerForElementAndIdentifier(itemWrapper, 'drop-area--item--component')
      imageController.upload(file, url)
    })
  }

  showItemWrapper() {
    const template = this.previewTemplateTarget.content.cloneNode(true)
    return this.element.insertAdjacentElement('beforeend', template.firstElementChild)
  }

  highlight(state) {
    if(typeof state == 'boolean') {
      this.imageInputTarget.classList.toggle('droparea__image-input--highlighted', state)
    } else {
      this.imageInputTarget.classList.toggle('droparea__image-input--highlighted')
    }
  }
}
