import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['close']
  static values = {
    fadeout: Boolean
  }

  connect() {
    if(this.fadeoutValue) {
      this.timeout = setTimeout(this.close.bind(this), 4000)
    }
  }

  disconnect() {
    if(this.timeout) clearTimeout(this.timeout)
  }

  close(e) {
    if(e) e.preventDefault()
    this.element.remove()
  }
}
