import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['overlay']

  toggle(visible) {
    this.overlayTarget.classList.toggle('is-active', visible)
    document.documentElement.classList.toggle('document--with-main-overlay-visible', visible)
  }

  show() {
    this.element.classList.add('is-active')
  }

  hide() {
    this.element.classList.remove('is-active')
  }
}
