import { Controller } from 'stimulus'
import { useClickOutside } from 'stimulus-use'
import { useToggleable } from "controllers/mixins/toggleable"

export default class extends Controller {
  static targets = ['popover']

  connect() {
    useClickOutside(this)
    useToggleable(this, "popover")
  }

  clickOutside() {
    this.close()
  }
}