import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import { put } from '@rails/request.js'

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      dataIdAttr: 'data-item-id',
      onEnd: this.onEnd.bind(this)
    })
  }

  async onEnd(event) {
    const url = this.data.get('url')
    const items = this.sortable.toArray().map(val => parseInt(val, 10))

    await put(url, { body: JSON.stringify({ items }) })
  }
}