import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['star', 'error']

  validate() {
    this.element.classList.toggle('review-star-input--unselected', !this.hasCheckedStar)

    if (this.hasErrorTarget && this.hasCheckedStar) {
      this.errorTarget.remove()
    }
  }

  get hasCheckedStar() {
    return this.starTargets.some(el => el.checked)
  }
}
