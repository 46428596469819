import { useClosable } from './closable'

export const useToggleable = (controller, targetName = 'toggleable', toggleClass = 'is-hidden') => (
  Object.assign(useClosable(controller, targetName, toggleClass), {
    toggle() {
      const targets = controller[`${targetName}Targets`] || [controller.element] // targets or self
      targets.forEach(element => element.classList.toggle(toggleClass))
    },

    open() {
      const targets = controller[`${targetName}Targets`] || [controller.element] // targets or self
      targets.forEach(element => element.classList.remove(toggleClass))
    }
  })
)