import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['item']

  initialize() {
    this.currentMenuId = null
  }

  get itemControllers() {
    return this.application.controllers.filter(c => c.identifier === 'main-menu-item')
  }

  activate(menuId) {
    if (menuId === this.currentMenuId) {
      this.hide()
      return false
    } else {
      this.itemControllers.forEach(item => item.activate(menuId))
      this.currentMenuId = menuId
      return true
    }
  }

  hide() {
    this.itemControllers.forEach(item => item.activate(null))
    this.currentMenuId = null
  }
}
