import { Controller } from 'stimulus'
import Uploader from 'lib/uploader'

export default class extends Controller {
  static values = {
    previewUrl: String,
    autosave: Boolean
  }

  static targets = [
    'media',
    'fileField',
    'inputSignedId',
    'turboFrame',
    'preview',
    'previewImageTemplate',
    'previewVideoTemplate',
    'placeholderTemplate',
    'progressVideoTemplate',
    'progressImageTemplate',
    'progressWrapper',
    'errorTemplate'
  ]

  disconnect() {
    if (this.uploadInstance) {
      this.uploadInstance.removeListeners()
    }
  }

  autosave() {
    this.element.closest('form').requestSubmit()
  }

  remove(e) {
    e.preventDefault()
    this.showPlaceholder()
  }

  abortUpload(e) {
    e.preventDefault()
    if (this.uploadInstance) {
      this.uploadInstance.abort()
      this.showPlaceholder()
    }
  }

  upload(e) {
    this.highlight(false)
    const files = e.dataTransfer ? e.dataTransfer.files : e.currentTarget.files
    this.uploadFile(files[0])
  }

  async uploadFile(file) {
    const url = this.fileFieldTarget.dataset.directUploadUrl
    this.showProgress(file)

    this.uploadInstance = new Uploader(file, url, this.progressWrapperTarget)

    try {
      const blob = await this.uploadInstance.run()
      this.success(blob, file)
    } catch (e) {
      /*global Rollbar*/
      if (typeof Rollbar !== 'undefined') Rollbar.error('Error upload post photo', e)
      this.showError(e)
    }
  }

  success(blob, file) {
    this.inputSignedId = blob.signed_id
    this.showPreview(file)
    this.inputSignedIdTarget.value = blob.signed_id

    if(this.autosaveValue) this.autosave()
  }

  showTemplate(file, template) {
    this.toggleState(false)
    let html = this[`${template}ImageTemplateTarget`].innerHTML
    if(file.type.toLowerCase().startsWith('video/')) {
      html = this[`${template}VideoTemplateTarget`].innerHTML
    }
    this.previewTarget.innerHTML = html
    this.displayMedia(file)
  }

  showProgress(file) {
    this.showTemplate(file, 'progress')
  }

  showPreview(file) {
    this.showTemplate(file, 'preview')
  }

  showPlaceholder() {
    this.toggleState(true)
    this.previewTarget.innerHTML = this.placeholderTemplateTarget.innerHTML
  }

  showError() {
    this.toggleState(true)
    this.previewTarget.innerHTML = this.errorTemplateTarget.innerHTML
  }

  displayMedia(file) {
    const blobURL = URL.createObjectURL(file);
    this.mediaTargets[this.mediaTargets.length - 1].src = blobURL
  }

  highlight(bool) {
    const className = 'media-uploader--highlight'
    if (typeof bool === 'boolean') {
      this.element.classList.toggle(className, bool)
    } else {
      this.element.classList.toggle(className)
    }
  }

  toggleState(bool) {
    this.element.classList.toggle('media-uploader--empty', bool)
    this.previewTarget.classList.toggle('media-uploader__preview--opaque', !bool)
  }
}
