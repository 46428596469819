import ReadMore from 'stimulus-read-more'
import { useIntersection } from 'stimulus-use'

export default class extends ReadMore {
  static targets = ['content', 'button']

  connect() {
    super.connect()

    if(this.isHidden(this.element)) {
      const [, unobserve] = useIntersection(this)
      this.unobserve = unobserve
    } else {
      this.checkButton()
    }
  }

  checkButton() {
    if (this.hasEllipsis) { this.showButton() }
    else this.hideButton()
  }

  appear() {
    this.checkButton()

    // observe and emit `appear()` callback just once
    this.unobserve()
  }

  // https://stackoverflow.com/a/64689702/2088562
  get hasEllipsis() {
    const e = this.contentTarget;
    var c = e.cloneNode(true);

    c.classList.add('read-more__content--clone');
    this.element.classList.add('read-more--block');

    this.element.appendChild(c);
    const truncated = c.offsetHeight > e.clientHeight;
    c.remove();

    this.element.classList.remove('read-more--block');

    return truncated;
  }

  // https://stackoverflow.com/a/38873788
  isHidden(e) {
    return !( e.offsetWidth || e.offsetHeight || e.getClientRects().length );
  }

  showButton() {
    this.buttonTarget.style.display = 'inline'
  }

  hideButton() {
    this.buttonTarget.style.display = 'none'
  }

  // // Function to override on toggle.
  // toggle(event) {}
  //
  // Function to override when the text is shown.
  show(event) {
    super.show(event)

    this.dispatch("translate-tags", { detail: event })
  }
  //
  // // Function to override when the text is hidden.
  // hide(event) {}
}
