import { Controller } from 'stimulus'
import { useToggleable } from "controllers/mixins/toggleable"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  connect() {
    useToggleable(this, '')
    useClickOutside(this)
  }

  leave(e) {
    if(this.element.getBoundingClientRect().height <= e.offsetY) {
      this.close()
    }
  }

  clickOutside(e) {
    if(this.userMenuIcon.contains(e.target) || this.userMenuIcon.isSameNode(e.target)) return

    this.close()
  }

  get userMenuIcon() {
    return this.application.controllers.find(c => c.identifier === 'menu-emitter').element
  }
}
