import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    itemId: String,
    navId: String
  }

  async toggle(event) {
    event.preventDefault()
    this.submenuControllers.forEach(item => item.activate(this.itemIdValue))
  }

  get submenuControllers() {
    return this.application.controllers.filter(c => c.identifier === 'main-submenu')
  }

  activate(itemId) {
    this.element.classList.toggle('main-menu__item--active', this.id === itemId)
  }
}
