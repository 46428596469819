// From https://ckeditor.com/docs/ckeditor5/latest/features/mentions.html#customizing-the-output
function MentionCustomization(editor) {
  // The upcast converter will convert view <span>
  // elements to the model 'mention' text attribute.
  editor.conversion.for('upcast').elementToAttribute({
    view: 'span',
    model: 'mention',
    converterPriority: 'high'
  });

  // Downcast the model 'mention' text attribute to a view <span> element.
  editor.conversion.for('downcast').attributeToElement({
    model: 'mention',
    view: 'span',
    converterPriority: 'high'
  });
}

export default MentionCustomization
