import { Controller } from 'stimulus'
// import { useClickOutside } from 'stimulus-use'
import { useClosable } from "controllers/mixins/closable"

export default class extends Controller {
  connect() {
    // useClickOutside(this)
    useClosable(this)
  }
  //
  // clickOutside(e) {
  //   e.preventDefault()
  //   this.close()
  // }
}
