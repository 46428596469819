import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['header', 'caret']

  static values = { open: Boolean }

  connect() {
    this.toggleState(this.openValue)
  }

  // TODO: need some better way to DRY it
  toggleState(state = null) {
    this.element.classList.toggle('groups-nav__item--open', state)
    this.headerTarget.classList.toggle('groups-nav__header--open', state)
    this.caretTarget.classList.toggle('groups-nav__caret--open', state)
  }

  async toggle() {
    if (this.caretVisible) {
      this.element.classList.toggle('groups-nav__item--open')
      this.headerTarget.classList.toggle('groups-nav__header--open')
      this.caretTarget.classList.toggle('groups-nav__caret--open')
    }
  }

  get caretVisible() {
    const el = this.caretTarget
    const style = window.getComputedStyle(el)
    return (style.display !== 'none')
  }
}
