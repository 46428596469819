import { DirectUpload } from '@rails/activestorage'

export default class {
  constructor(file, url, el = null) {
    this.el = el // progress indicator html element
    this.upload = new DirectUpload(file, url, this)
  }

  // DEPRECATED: https://github.com/sam-github-org/m2y-backend-app/issues/895
  //
  // run(callback = null, handleError = null) {
  //   this.upload.create((error, blob) => {
  //     if (error) {
  //       if (handleError) handleError(error)
  //
  //       return
  //     }
  //
  //     if (callback) { callback(blob) }
  //   })
  // }

  run() {
    const { upload } = this

    return new Promise((resolve, reject) => {
      upload.create((error, blob) => {
        if (error) {
          reject(error)
        } else {
          resolve(blob)
        }
      })
    })
  }

  abort() {
    if (this.request) {
      this.request.abort()
    }
  }

  directUploadWillStoreFileWithXHR(request) {
    request.setRequestHeader('x-amz-acl', 'public-read')

    this.removeListeners()
    this.request = request
    request.upload.addEventListener("progress", this.directUploadDidProgress.bind(this))
  }

  // update progress indicator
  directUploadDidProgress(event) {
    if (this.el == null) return

    const progress = this.el.querySelector('progress')
    progress.value = Math.round(event.loaded * 100 / event.total)
  }

  removeListeners() {
    if (!this.request) return

    this.request.upload.removeEventListener("progress", this.directUploadDidProgress.bind(this))
  }
}
