export const useClosable = (controller, targetName = 'closable', toggleClass = 'is-hidden') => (
  Object.assign(controller, {
    escape(e) {
      if (e.key === 'Escape') controller.close()
    },

    close() {
      const targets = controller[`${targetName}Targets`] || [controller.element] // targets or self
      targets.forEach(element => element.classList.add(toggleClass))
    }
  })
)
