import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['mobile']

  toggleMobile() {
    this.mobileTarget.classList.toggle('mobile-navbar--hidden')
    document.documentElement.style.overflow = document.documentElement.style.overflow == 'hidden' ? '' : 'hidden'
  }
}
