import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.openContainer()
    this.element.scrollIntoView({ block: "start", inline: "nearest" })
  }

  openContainer() {
    const el = this.element.closest('li')
    const containerController = this.application.getControllerForElementAndIdentifier(el, 'groups-nav--component')
    containerController?.toggleState(true)
  }
}
