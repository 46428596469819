import Controller from '../component_controller'

export default class extends Controller {
  appear() {
    this.handleVisiblePlayers()
  }

  disappear() {
    this.handleVisiblePlayers()
  }

  async handleVisiblePlayers() {
    const controllers = this.application.controllers.filter(c => c.identifier == this.identifier)
    const firstVisibleController = controllers.find(c => c.isVisible())
    for (const c of controllers) {
      if(!c.player) continue;

      if(c === firstVisibleController) {
        c.playPromise = c.player.play()
      } else {
        if (c.playPromise) {
          await c.awaitPlayPromise()
        }
        c.player.pause()
      }
    }
  }
}
