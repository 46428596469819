import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['spinner', 'button']

  more(e) {
    e.preventDefault()

    const url = this.buttonTarget.href

    this.spinnerTarget.classList.toggle('load-more__spinner--visible')
    this.buttonTarget.remove()

    fetch(url, { headers: { Accept: "text/vnd.turbo-stream.html" } })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }
}
