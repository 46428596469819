import { Controller } from 'stimulus'

export default class extends Controller {
  // static targets = ['main', 'thumbLink', 'thumbWrapper']

  toggle(event) {
    event.preventDefault()

    const url = this.data.get('toggleUrl')
    const added = this.data.get('added')

    console.log('toggle wishlist')
    console.log(url, added)
  }

  // initialize() {
  //   this.imageIndex = 0
  // }
  //
  // connect() {
  //   this.update()
  // }
  //
  // show(event) {
  //   event.preventDefault()
  //   this.imageIndex = this.thumbLinkTargets.indexOf(event.currentTarget )
  //
  //   this.update()
  // }
  //
  // next() {
  //   this.imageIndex = (this.imageIndex == this.thumbLinkTargets.length - 1) ? 0 : this.imageIndex + 1
  //   this.update()
  // }
  //
  // prev() {
  //   this.imageIndex = (this.imageIndex == 0) ? (this.thumbLinkTargets.length - 1) : this.imageIndex - 1
  //   this.update()
  // }
  //
  // update() {
  //   const selectedEl = this.thumbLinkTargets[this.imageIndex]
  //
  //   // highlight thumbnail
  //   this.thumbWrapperTargets.forEach(el => {
  //     const linkEl = el.querySelector('a')
  //     el.classList.toggle('product-gallery__thumbnail--selected', linkEl === selectedEl)
  //   })
  //
  //   // update main image
  //   this.mainTarget.src = selectedEl.href
  // }
}
