import { Controller } from 'stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  connect() {
    useClickOutside(this)
    this.selectedIndex = 0
  }

  toggle(event) {
    event.preventDefault()
    this.element.classList.toggle("product-options-dropdown--opened")
  }

  clickOutside(event) {
    this.close()
  }

  close() {
    this.element.classList.remove("product-options-dropdown--opened")
  }

  choose(event) {
    this.close()
  }
}
