import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['circle']

  toggle(e) {
    this.circleTargets.forEach(element => {
      element.classList.toggle("sliding-menu-sort__circle--selected", e.currentTarget.contains(element))
    });
  }
}