import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['item', 'list', 'wrapper', 'arrowNext', 'arrowPrev']

  initialize() {
    this.offsetPos = 0;
    this.updateArrows()
  }

  async next(e) {
    e.preventDefault()
    const rest = this.itemsCount - (this.itemsDisplayed + this.offsetPos)
    this.update(Math.min(rest, 1))
  }

  async prev(e) {
    e.preventDefault()
    this.update(-Math.min(this.offsetPos, 1))
  }

  update(step) {
    this.offsetPos += step
    this.listTarget.style.marginLeft = `${-this.offsetPos * this.stepSize}px`

    this.updateArrows()
  }

  updateArrows() {
    this.updateNextArrow()
    this.updatePrevArrow()
  }

  updateNextArrow() {
    this.arrowNextTarget.classList.toggle('thumbnails-list__arrow--disabled', !this.nextEnabled)
  }

  updatePrevArrow() {
    this.arrowPrevTarget.classList.toggle('thumbnails-list__arrow--disabled', !this.prevEnabled)
  }

  get prevEnabled() {
    return this.offsetPos > 0
  }

  get nextEnabled() {
    return (this.itemsDisplayed + this.offsetPos) < this.itemsCount
  }

  get stepSize() {
    return this.itemTarget.offsetWidth
  }

  get wrapperSize() {
    return this.wrapperTarget.offsetWidth
  }

  get itemsCount() {
    return this.itemTargets.length
  }

  get itemsDisplayed() {
    return this.wrapperSize / this.stepSize
  }
}
