import { ApplicationController } from 'stimulus-use'
import { useClickOutside } from 'stimulus-use'
import { put, get } from '@rails/request.js'
import Tagify from '@yaireo/tagify'

export default class extends ApplicationController {
  static values = {
    url: String,
    videoId: String
  }
  static targets = ['input']

  connect() {
    useClickOutside(this)

    this.tagify = new Tagify(this.inputTarget, {
      whitelist: [],
      templates: {
        tag(tagData) {
          const value = tagData[this.settings.tagTextProp] || tagData.value
          return `
          <tag
            title="${(tagData.title || tagData.value)}"
            contenteditable='false'
            spellcheck='false'
            tabIndex="${this.settings.a11y.focusableTags ? 0 : -1}"
            class="${this.settings.classNames.tag} ${tagData.class ? tagData.class : ""}"
            ${this.getAttributes(tagData)}
          >
            <div class="tagify__tag-text-wrapper">
              <span class="${this.settings.classNames.tagText}">${value}</span>
            </div>
            <svg data-action="click->video--tags-editor--component#removeTag" data-value="${value}" role='button' aria-label='remove tag' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.7635 11.9728L23.7115 2.05141C23.908 1.82246 24.0108 1.52796 23.9991 1.22676C23.9874 0.925553 23.8622 0.639832 23.6485 0.42669C23.4348 0.213548 23.1483 0.0886838 22.8463 0.0770496C22.5443 0.0654154 22.249 0.167869 22.0195 0.363934L12.0715 10.2853L2.12345 0.351966C1.89749 0.126606 1.59101 0 1.27145 0C0.95189 0 0.645417 0.126606 0.419452 0.351966C0.193488 0.577326 0.0665421 0.88298 0.0665421 1.20169C0.0665421 1.52039 0.193488 1.82605 0.419452 2.05141L10.3795 11.9728L0.419452 21.8942C0.293834 22.0015 0.19181 22.1335 0.119782 22.282C0.0477538 22.4305 0.00727762 22.5922 0.000894229 22.757C-0.00548916 22.9218 0.0223574 23.0862 0.0826869 23.2398C0.143016 23.3934 0.234526 23.5328 0.351472 23.6495C0.468418 23.7661 0.608274 23.8574 0.762265 23.9175C0.916255 23.9777 1.08105 24.0055 1.24632 23.9991C1.41158 23.9927 1.57374 23.9524 1.72262 23.8805C1.87149 23.8087 2.00388 23.707 2.11145 23.5817L12.0715 13.6603L22.0195 23.5817C22.249 23.7777 22.5443 23.8802 22.8463 23.8686C23.1483 23.8569 23.4348 23.7321 23.6485 23.5189C23.8622 23.3058 23.9874 23.0201 23.9991 22.7188C24.0108 22.4176 23.908 22.1231 23.7115 21.8942L13.7635 11.9728Z" fill="#959595"/>
            </svg>
          </tag>`
        }
      },
      dropdown: {
        maxItems: 20,
        enabled: 1,
        closeOnSelect: true
      },
      delimiters: "#|,"
    })

    this.tagify.DOM.input.setAttribute("data-action", "input->video--tags-editor--component#searchTags")
  }

  disconnect() {
    this.tagify.destroy()
  }

  clickOutside() {
    this.tagify.dropdown.hide()
  }

  async searchTags() {
    const q = this.tagify.DOM.input.textContent
    if(q.length >= 2) {
      const url = `${this.urlValue}?q=${q}`

      const { response } = await get(url, { responseKind: "json" })
      const data = await response.json()
      console.log(data.tags)

      this.tagify.whitelist = data.tags
      this.tagify.dropdown.show()
    } else {
      this.tagify.whitelist = []
      this.tagify.dropdown.hide()
    }
  }

  removeTag(event) {
    this.tagify.removeTags(event.currentTarget.dataset.value)
  }

  async changeTags() {
    const url = this.urlValue
    const tags = this.tagify.value.map(tag => tag.value)

    await put(url, { body: JSON.stringify({ tags }) })
  }
}
