import Controller from '../component_controller'
import { useOverlay } from "controllers/mixins/overlay"

export default class extends Controller {
  connect() {
    useOverlay(this)
  }

  close() {
    this.element.classList.add('is-hidden')
    this.hideOverlay()
  }
}
